<template>
  <div class="flex w-full h-screen">
    <SideBar />

    <div class="w-full">
      <header
        class="w-full sticky top-0 bg-white shadow-sm bg-white h-[4rem] flex items-center justify-between"
      >
        <div
          class="container px-[5%] sm:pr-2 sm:px-5 flex gap-[10px] items-center justify-between w-full"
        >
          <router-link
            to="/"
            class="avatar flex items-center gap-3 justify-center"
            @click="$emit('open-modal')"
          >
            <p class="text-2xl font-bold text-green-600">
              <span v-if="$route.meta.title == undefined">CAMPUSCTZN</span>
              <span v-else>{{ $route.meta.title }} </span>
            </p>
          </router-link>

          <button
            class="btn px-4 md:px-6 py-2 md:py-2.5 bg-slate-800 text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex rounded-lg sm:rounded-none items-center"
            type="button"
            id="button-addon2"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="search"
              class="w-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
              ></path>
            </svg>
          </button>
        </div>
      </header>

      <Navigation />

      <main class="p-3 sm:p-5 scrollbar-hide pb-[5rem]">
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/NavigationComponent.vue';
import SideBar from '@/components/SideBar.vue';

export default {
  name: 'HomeViewLayout',
  setup() {},

  components: {
    Navigation,
    SideBar,
  },
};
</script>

<style scoped>
.logo {
  color: #222222;
  font-weight: 700;
}
</style>
