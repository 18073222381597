<template>
  <PWAPrompt />

  <DefaultLayout>
    <router-view v-slot="{ Component }" v-model="layout">
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </DefaultLayout>

  <!-- <CreateClick /> -->
</template>



<script>
import PWAPrompt from "@/components/PWAPrompt.vue";

export default {
  name: "App",
  components: {
    PWAPrompt,
  },
  methods: {
    logout() {
      this.$store.commit("logout");
    },
  },
  mounted() {
    this.$store.commit("initializeStore");
  },
};

// const modalActive = ref(null);
// const toggleModal = () => {
//   modalActive.value = !modalActive.value;
// };
</script>


<style>
/* route transition */
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.route-enter-active {
  transition: ass 0.3s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>