<template>
  <component :is="layout">
    <slot />
  </component>
</template>


<script>
import { shallowRef, watch } from "vue";
import DefaultViewLayout from "./DefaultViewLayout.vue";
import { useRoute } from "vue-router";

export default {
  name: "DefaultLayout",
  setup() {
    const layout = shallowRef(DefaultViewLayout);
    const route = useRoute();

    watch(
      () => route.meta,
      async () => {
        try {
          const component = await require(`@/layouts/${route.meta.layout}.vue`);
          layout.value = component?.default || DefaultViewLayout;
        } catch (e) {
          layout.value = DefaultViewLayout;
        }
      }
    );

    return { layout };
  },
};
</script>
