<template>
  <header
    class="w-full sticky top-0 shadow-sm bg-[#130912] h-[4rem] text-[#fafafa] flex items-center justify-between"
  >
    <div
      class="w-full px-[5%] sm:px-5 flex gap-[10px] items-center justify-between text-center w-full relative"
    >
      <div
        class="menu icon cursor-pointer hover:text-[#e77917] text-2xl md:text-3xl md:hidden"
        @click="toggle()"
      >
        <font-awesome-icon icon="fa-solid fa-bars" />
      </div>

      <router-link to="/" class="avatar flex items-center gap-3 justify-center">
        <div class="logo h-[2.25rem] md:h-[2.8rem]">
          <img
            src="../assets/logo-trans.png"
            alt="CAMPUSCTZN Logo"
            class="h-full object-contain"
          />
        </div>
      </router-link>

      <div class="flex items-center gap-8 text-xl">
        <router-link
          to="/profile"
          class="avatar flex items-center gap-2 justify-center bg-[#e77917] rounded-lg h-[2rem] w-[2rem] md:h-[2.25rem] md:w-[2.25rem] overflow-hidden"
        >
          <img
            src="https://res.cloudinary.com/practicaldev/image/fetch/s--BwSU14_J--/c_fill,f_auto,fl_progressive,h_90,q_auto,w_90/https://dev-to-uploads.s3.amazonaws.com/uploads/user/profile_image/715366/cf7d92f6-98be-42bf-8d3d-6e192b562d1e.png"
            alt=""
            class="object-cover h-full w-full flex-shrink-0"
            height="20"
            width="20"
            loading="lazy"
          />
        </router-link>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
header {
  z-index: 1000;
  border-bottom: 1px solid #fafafa;
}
</style>

<script>
// import AppLink from './AppLink.vue';

export default {
  setup() {},

  methods: {
    toggle() {
      this.$emit('toggle');
    },
  },
  components: {},
};
</script>
