import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('@/views/home/HomeIndex.vue'),
    meta: {
      // layout: 'HomeViewLayout',
      title: 'Home',
    },
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: () => import('@/views/profile/ProfileIndex.vue'),
    meta: {
      title: 'Profile',
    },
  },
  {
    path: '/news',
    name: 'NewsView',
    component: () => import('@/views/services/NewsFeed/NewsFeedIndex.vue'),
    redirect: '/',
    meta: {
      title: 'NewsFeed',
    },
  },
  {
    path: '/notifications',
    name: 'NotificationView',
    component: () => import('@/views/notifications/NotificationIndex.vue'),
    meta: {
      title: 'Notifications',
    },
  },
  {
    path: '/instant',
    name: 'InstantIndex',
    component: () => import('@/views/services/Instant/InstantIndex.vue'),
    meta: {
      title: 'Instant',
    },
  },

  {
    path: '/marketplace',
    name: 'MarketPlaceView',
    component: () =>
      import('@/views/services/Marketplace/MarketplaceIndex.vue'),
    meta: {
      title: 'Marketplace',
      // layout: 'MarketplaceLayout',
    },
  },
  {
    path: '/campusexplorer',
    name: 'CampusIndexView',
    component: () =>
      import('@/views/services/CampusExplorer/CampusIndexView.vue'),
    meta: {
      title: 'CampusExplorer',
    },
    children: [],
  },

  {
    path: '/places',
    name: 'PlacesView',
    props: true,
    component: () => import('@/views/services/CampusExplorer/PlacesView.vue'),
    children: [],
  },
  {
    path: '/events',
    name: 'ViewEvents',
    props: true,
    component: () => import('@/views/services/CampusExplorer/EventsView.vue'),
  },
  {
    path: '/places/:category',
    name: 'ViewPlaces',
    props: true,
    component: () => import('@/views/services/CampusExplorer/ViewPlaces.vue'),
  },

  {
    path: '/eduportal',
    name: 'IndexPortalView',
    component: () => import('@/views/services/EduPortal/IndexPortalView.vue'),
    meta: {
      title: 'EduPortal',
    },
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: () => import('@/views/settings/SettingsIndex.vue'),
    meta: {
      title: 'Settings',
    },
  },
  {
    path: '/features/gpcalculator',
    name: 'GpCalcView',
    component: () => import('@/views/services/EduPortal/GpCalcView.vue'),
  },
  {
    path: '/chat',
    name: 'ChatView',
    component: () => import('@/views/services/Chat/ChatIndex.vue'),
    meta: {
      title: 'Chat',
    },
  },
  {
    path: '/features/screening-result',
    name: 'ScreeningResult',
    component: () => import('@/views/services/EduPortal/ScreeningResult.vue'),
    meta: {
      title: 'Screening Result',
    },
  },
];

const router = createRouter({
  linkActiveClass: 'is-active',
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const title = () => {
    if (to.meta.title == undefined) {
      return 'CAMPUSCTZN';
    } else {
      return `CAMPUSCTZN - ${to.meta.title}`;
    }
  };
  document.title = title();
  next();
});

export default router;
