import { createApp } from 'vue';
import App from './App.vue';
import './index.scss';
import DefaultLayout from './layouts/DefaultLayout.vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import {
  faBars,
  faAdd,
  faSearch,
  faEnvelope,
  faLocationDot,
  faEnvelopesBulk,
  faUpload,
  faGraduationCap,
  faCalculator,
  faBookOpen,
  faCalendarWeek,
  faUsersBetweenLines,
  faServer,
  faChevronRight,
  faBolt,
  faWandSparkles,
  faGear,
  faArrowRightFromBracket,
  faPen,
  faSort,
  faInfinity,
  faInbox,
  faStore,
  faTableColumns,
  faHouseChimneyUser,
  faCaretRight,
  faFilter,
  faArrowDownWideShort,
  faEllipsisVertical,
  faArrowLeft,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBell,
  faNewspaper,
  faCalendarDays,
  faCommentDots,
  faUser,
  faClock,
  faComments,
} from '@fortawesome/free-regular-svg-icons';
import router from './router';
import './registerServiceWorker';
import store from './store';
import { faSellcast } from '@fortawesome/free-brands-svg-icons';

library.add(
  faBars,
  faAdd,
  faBell,
  faSearch,
  faEnvelope,
  faNewspaper,
  faCalendarDays,
  faEnvelopesBulk,
  faLocationDot,
  faUpload,
  faGraduationCap,
  faCalculator,
  faUsersBetweenLines,
  faBookOpen,
  faCommentDots,
  faUsersBetweenLines,
  faServer,
  faChevronRight,
  faCalendarWeek,
  faBolt,
  faWandSparkles,
  faGear,
  faPen,
  faClock,
  faUser,
  faSort,
  faArrowRightFromBracket,
  faInfinity,
  faSellcast,
  faInbox,
  faComments,
  faStore,
  faTableColumns,
  faHouseChimneyUser,
  faCaretRight,
  faFilter,
  faArrowDownWideShort,
  faEllipsisVertical,
  faArrowLeft,
  faHome
);

createApp(App)
  .use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('DefaultLayout', DefaultLayout)
  .mount('#app');

// router.isReady().then(() => {
//   createApp(App).mount('#app');
// });
