<template>
  <div class="w-full h-screen">
    <Header @toggle="toggle()" />
    <div class="w-full relative flex new-height">
      <SideBar @toggle="toggle()" :isActive="isActive" />

      <Navigation />

      <main class="p-3 sm:p-5 scrollbar-hide pb-[5rem]">
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
// import MoreSider from '@/components/MoreSider.vue';
import Header from '@/components/HeaderComponent.vue';
import Navigation from '@/components/NavigationComponent.vue';
import SideBar from '@/components/SideBar.vue';

export default {
  name: 'DefaulltViewLayout',
  setup() {},

  data() {
    return { isActive: true };
  },

  methods: {
    toggle() {
      this.isActive = this.isActive ? false : true;
    },
  },

  components: {
    Navigation,
    SideBar,
    Header,
    // MoreSider,
  },
};
</script>

<style scoped>
.logo {
  color: #222222;
  font-weight: 700;
}
.new-height {
  height: calc(100vh - 4rem);
}
</style>
